<!--  -->
<template>
  <div :class="myslh=='elder'?'old':''">
    <div class="head">
      <!-- <p>证件号码</p> -->
      <input type="text" placeholder="请输入证件号码" v-model="num" />
      <div>
        <button @click="chak">查询</button> <button @click="del">重置</button>
      </div>
    </div>
    <!-- <ul class="tab">
      <li>
        <p class="name">信访人</p>
        <p class="text">主要诉求</p>
        <p class="jd">代办进度</p>
      </li>
      <li v-for="item in list" :key="item.id">
        <p class="name">{{ item.name }}</p>
        <p class="text">
          {{ item.appeal }}
        </p>
        <p class="jd">{{ item.result }}</p>
      </li>
      <div>
      <p class="iconfont icon-zanwu zanwu"></p><p class="txt">暂无报表</p>
      </div>
    </ul> -->
    <div class="plan_c" v-show="list">
      <div
        class="plan_b"
        v-for="item in list"
        :key="item.id"
        @click="content(item.id)"
      >
        <p class="p_head">
          <span>{{ item.name }}</span>
          <span class="p_txt"
            >结果：{{
              item.status == 1 || item.status == 3 ? "进行中" : "已完成"
            }}</span
          >
        </p>
        <dd class="p_content">
          {{ item.main_appeal }}
        </dd>
        <div class="btt">
          <p class="time">
            提交时间：<span>{{ item.registration_date }}</span>
          </p>
          <p class="db_people">
            代办人：<span>{{ item.agent_name }}</span>
          </p>
        </div>
      </div>
    </div>
    <p class="iconfont icon-zanwu zanwu" v-show="!list"></p>
    <p class="txt" v-show="!list">暂无报表</p>
  </div>
</template>

<script>
import "../../assets/css/content/commission.css";
import { Toast } from 'vant';
export default {
  data() {
    return {
      num: "",
      list: [],
      myslh: sessionStorage.getItem('getslh'),
    };
  },
  mounted() {
    let that = this;
    this.$get("/front/petition/list", {status: 2})
      .then(function (res) {
        that.list = res.list;
        console.log(that.list);
      })
      .catch(function (err) {
        console.log(err);
      });
  },
  methods: {
     chak() {
      let num = this.num;
      console.log(num);
      let that = this;

      this.$get("/front/petition/list", {status: 2,search:num})
        .then(function (res) {
          if(res.status==1){
            that.list = res.list;
          }else{
            that.list=[]
            Toast(res.msg)
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    del() {
      this.num = "";
    },
    content(id) {
      this.$router.push({
        path: "/comtent",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style scoped>
.plan_c {
  overflow: hidden;
  padding-bottom: 10px;
}
.plan_b {
  width: 95%;
  /* height: 200px; */
  margin: 5px auto 0;
  box-shadow: 0 0 4px 2px #ebebeb;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 15px;
}

.plan_b .p_head {
  height: 45px;
  overflow: hidden;
  line-height: 50px;
  padding: 0 15px;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  /* border-bottom: 1px dashed whitesmoke; */
}
/* .hs{
  background-color: #E55270;
}
.ls{
  background-color: #9FDA9F;
} */
.p_txt {
  position: relative;
  top: 1px;
  float: right;
  font-size: 14px;
  color: #4f94cd;
}
.p_content {
  padding: 12px 15px;
  padding-top: 2px;
  color: #9c9c9c;
}
.btt {
  overflow: hidden;
  color: #7a7a7a;
  padding: 10px 15px;
  border-top: 1px solid whitesmoke;
  background-color: #f5f5f5;
}
.time {
  float: left;
}
.time span {
  color: #9c9c9c;
}
.db_people {
  float: right;
}
.db_people span {
  color: #9c9c9c;
}

.old .head p{
  font-size: 20px;
  width: 100%;
  margin-bottom: 10px;
  display: none;
}

.old .head input{
  margin-left: 0;
  height: 39px;
  font-size: 16px;
  width: calc(100% - 140px)
}
.old .head div{
  width: 140px;
}
.old .head button{
  height: 40px;
  width: 60px;
}

.old .plan_b .p_head{
  font-size: 22px;
}

.old .plan_b .p_head .p_txt{
  font-size: 18px;
}
.old .p_content{
  font-size: 18px;
}

.old .btt p{
  font-size: 16px;
}

.old .btt1 p{
  font-size: 16px;
}
</style>